.roadtwozero{
    margin-top: 90px;
    width: 100%;
    min-height: 500px;
}
.roadtwozero_title{
    font-family: 'Real Truth';
    font-size: 36px;
    margin-bottom: 60px;
    margin-left: 0;
}
.roadtwozero_wrapper{
    padding: 22px;
    margin-bottom: 90px;
}
.roadtwozero_description{
    font-family: 'Roboto';
    font-size: 26px;
    line-height: 1.2;
    letter-spacing: 1px;
}
.roadtwozero_links{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.roadtwozero_link{
    display: inline-block;
    width: 220px;
    text-align: center;
    text-decoration: none;
    font-family: 'izvest';
    border-radius: 5px;
    font-size: 22px;
    background-color: inherit;
    margin-right: 0;
    margin-bottom: 50px;
}
@media (max-width: 624px) {
    .roadtwozero_link{
        display: block;
        margin-bottom: 50px;
    }
}
@media (max-width: 524px) {
    .roadtwozero_link{
        width: 100%;
        margin-bottom: 50px;
    }
}
