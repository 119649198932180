.footer{
    margin-top: 100px;
    width: 100%;
    min-height: 40px;
}

.footer_description{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-size: 18px;

    margin-left: 0;
}
.footer_img{
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 0 25px 0 0;
}
@media (max-width: 1024px) {
    .footer_description{
        font-size: 16px;
    }
    .footer_img{
        width: 20px;
        height: 20px;
    }
}
@media (max-width: 824px) {
    .footer_description{
        font-size: 15px;
    }
    .footer_img{
        width: 19px;
        height: 19px;
    }
}