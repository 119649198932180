.header_top{
    margin: 35px auto ;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    font-family: 'Real Truth';
}
@media (max-width:1140px) {
    .header_top{
        height: 80px;
    }
}
@media (max-width: 1024px) {
    .header_top{
        height: 60px;
    }
}
@media (max-width: 850px) {
    .header{
        position: relative;
    }
    .header_top{
        height: 44px;
        margin: 30px auto 20px;
    }
}
@media (max-width: 624px) {
    .header_top{
        height: 600px;
        display:flex;
        flex-direction: column-reverse;
        margin: 40px auto 100px;
    }
}

