.biennale{
    position: relative;
    margin-top: 90px;
    width: 100%;
    min-height: 500px;
}
.biennale_title{
    font-family: 'Real Truth';
    font-size: 36px;
    margin-bottom: 60px;
    margin-left: 0;
}
.biennale_image{
    width: 100%;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 70px;
}
.biennale_foto{
    width: 580px;
    height: 340px;
    border: none;
    border-radius: 4px;
    object-fit: cover;
}
.biennale_close{
    position: fixed;
    right: 28%;
    bottom: 70px;
}
.biennake_btn{
    width: 220px;
    text-align: center;
    font-size: 21px;
    font-family: 'izvest';
    padding: 6px;
    border: 3px solid black;
    border-radius: 4px;
    font-size: 22px;
    background-color: inherit;
    cursor: pointer;
    background-color:#F5DEB3 ;
}
@media (max-width: 2250px) {
    .biennale_close{
        right: 24%;
        bottom: 30px;
    }
}
@media (max-width: 1850px) {
    .biennale_close{
        right: 20%;
        bottom: 30px;
    }
}
@media (max-width: 1650px) {
    .biennale_close{
        right: 16%;
        bottom: 30px;
    }
}
@media (max-width: 1440px) {
    .biennale_close{
        right: 10%;
        bottom: 30px;
    }
}
@media (max-width: 1200px) {
    .biennale_close{
        right: 26%;
        bottom: 30px;
    }
}
@media (max-width: 1124px) {
    .biennale_close{
        right: 26%;
        bottom: 30px;
    }
}
@media (max-width: 1024px) {
    .biennale_close{
        right: 22%;
        bottom: 30px;
    }
}
@media (max-width: 924px) {
    .biennale_close{
        right: 20%;
        bottom: 30px;
    }
}
@media (max-width:724px) {
    .biennale_title{
        font-size: 34px;
        margin-bottom: 40px;
    }
    .biennale_close{
        right: 10%;
        bottom: 30px;
    }
}
@media (max-width: 624px) {
    .biennale_foto{
        width: 100%;
        height: 330px;
    }
    .biennale_close{
        right: 5%;
        bottom: 20px;
    }
}
@media (max-width: 524px) {
    .biennale_foto{
        height: 300px;
    }
}
@media (max-width: 424px) {
    .biennale_title{
        font-size: 30px;
        margin-bottom: 30px;
    }
    .biennale_foto{
        height: 280px;
    }
    .biennale_close{
        right: 3%;
        bottom: 12px;
    }
}
@media (max-width: 350px) {
    .biennale_foto{
        height: 250px;
    }
}

