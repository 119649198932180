.aboutproject{
    margin-top: 90px;
    width: 100%;
}
.about_title{
    font-family: 'Real Truth';
    font-size: 36px;
    margin-bottom: 20px;
    margin-left: 0;
}

.about_info-top{
    display: flex;
    width: 100%;
}
.about_text{
    padding: 22px;
    font-family: 'izvest';
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: 1px;
    width: 60%;
}
.neste{
    font-family: Roboto;
    font-size: 28px;
}
.text_num{
    font-family: 'Roboto';
    font-size: 30px;
    font-weight: bold;
}
.about_img{
    width: 40%;
    height: 320px;
    border: none;
    border-radius: 4px;
    margin-right: 0;
    object-fit: cover;
}
.about_future{
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
}
@media (max-width:1140px) {
    .about_title{
        margin: 0 0 20px 15px;
    }

}
@media (max-width:1024px) {
    .about_title{
        font-size: 30px;
    }
    .about_text{
        font-size: 22px;
        line-height: 1.2;
    }
    .about_img{
        height: 280px;
    }
}
@media (max-width: 924px) {
    .about_title{
        font-size: 30px;
    }
    .about_text{
        font-size: 22px;
        line-height: 1.2;
        letter-spacing: 1px;
    }
    .about_text-num{
        font-size: 26px;
    }
    .about_img{
        height: 260px;
    }
}
@media (max-width: 624px) {
    .about_info-top{
        flex-direction: column;
    }
    .about_future{
        flex-direction: column;
    }
    .about_img{
        width: 96%;
        height: 330px;
        margin: 0 auto;
    }
    .about_text{
        width: 100%;
        padding: 14px;
    }
    .about_future{
        margin-top: 50px;
    }
}
@media (max-width: 500px) {
    .about_img{
        height: 260px;
    }
}
@media (max-width: 424px) {
    .about_future{
        margin-top: 30px;
    }    
}