
.not_page{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: #000000;
}
.page_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
}
.not_page-title{
    color: #FFFF00;
    font-family: 'Roboto';
    font-size: 46px;
    font-weight: bold;
    margin-bottom: 30px;
}
.link_home{
    color: #D3D3D3;
    font-family: 'Roboto';
    font-size: 26px;
    font-weight: bold;
    text-decoration: none;
}