.navigation{
    width: 100%;
    margin-bottom: 30px;
    z-index: 1;
}
.navigstion_active{
    display:  block;

}
.navigation__list{
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}
.navigation__link{
    text-decoration: none;
    padding: 22px;
    font-family: 'Roboto';
    font-size: 32px;
    font-weight: bold;
    color: #000000;
    line-height: 1.2;
    letter-spacing: 1px;
}
@media (max-width:1140px) {
    .navigation__link{
        font-size: 28px;
        line-height: 1.1;
        letter-spacing: 1px;
    }
}
@media (max-width:1024px) {
    .navigation__link{
        font-size: 24px;
        line-height: 1.1;
        letter-spacing: 1px;
    }
}
@media (max-width: 924px) {
    .navigation__link{
        font-size: 20px;
        line-height: 1;
    }
}
@media (max-width: 850px) {
    .navigation{
        display: none;
    }
    .navigation_active{
        display: block;
    }
    .navigation{
        position: absolute;
        width: 300px;
        top: 50px;
        right: 0;
        border: none;
        margin-bottom: 0;
    }
    .navigation__list{
        height: 370px;
        flex-direction: column;
        justify-content: space-between;
    }
    .navigation__item{
        width: 90%;
        text-align: center;
        border: 3px solid;
        border-radius: 20px;
        padding: 5px 10px;
        background-color: #F5DEB3;
        margin-right: 0;
    }
    .navigation__link{
        font-size: 25px;
        line-height: 1.1;
        letter-spacing: 0;
    }
}
@media (max-width: 624px) {
    .header_burger-content{
        margin-right: 15px;
    }
}