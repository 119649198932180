.header_burger-content{
    display: flex;
    align-items: center;
    width: 30px;
}
.header_burger{
    display: none;
    position: relative;
    width: 30px;
    height: 18px;
    color: #000000;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.burger__line{
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%) scale(1);
    background-color: #000000;
    transition: transform 0.3s;
}
.burger::before,.burger::after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #000000;
    transition: transform 0.3s ease, top 0.3s ease;
}
.burger::before{
    top: 0;
}
.burger::after{
    bottom: 0;
}
.burger_active .burger__line{
    transform: translateY(-50%) scale(0);
}
.burger_active::before{
    top: 50%;
    transform: rotate(-45deg);
}
.burger_active::after{
    top: 50%;
    transform: rotate(45deg);
    bottom: auto;
}

@media (max-width:830px) {
    .burger{
        display: block;
    }
}
@media (max-width: 624px) {
    .header_burger-content{
        margin-right: 10px;
    }
}

