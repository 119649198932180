.contacts{
    margin-top: 100px;
    width: 100%;
    min-height: 100px;
}
.contacts_title{
    font-family: 'Real Truth';
    font-size: 36px;
    margin-bottom: 30px;
}
.contacts_wrapper{
    display: inline-flex;
    flex-direction: column;
}

.social_img{
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
    color: black;
}
@media (max-width: 1140px) {
    .contacts_title{
        margin: 0 0 30px 15px;
    }
}
@media (max-width: 1024px) {
    .contacts_title{
        font-size: 30px;
    }
    .social_img{
        width: 40px;
        height: 40px;
    }
}
@media (max-width: 824px) {
    .contacts_title{
        font-size: 30px;
    }
    .social_img{
        width: 34px;
        height: 34px;
    }
}