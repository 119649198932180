.gallery{
    margin-top: 90px;
    width: 100%;

}
.gallery_title{
    font-family: 'Real Truth';
    font-size: 36px;
    margin-bottom: 20px;
}
.gallery_wrapper{
    position: relative;
    display: flex;
    width: 100%;
}
.gallery_description{
    padding: 22px;
    font-family: 'izvest';
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: 1px;
    margin-bottom: 40px;
}
.gallery_title{
    margin-left: 0;
}
.gallery_text{
    padding: 22px;
    font-family: 'izvest';
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: 1px;
    margin-bottom: 50px;
}
.gallery_link{
    width: 0px;
    height: 0px;
}
.gallery_btn{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 220px;
    text-align: center;
    font-size: 21px;
    font-family: 'izvest';
    padding: 6px;
    border: 4px solid black;
    border-radius: 4px;
    font-size: 22px;
    background-color: inherit;
    cursor: pointer;
}
@media (max-width: 1140px) {
    .gallery_title{
        margin: 0 0 20px 15px;
    }
}
@media (max-width:1024px) {
    .gallery_title{
        font-size: 30px;
    }
    .gallery_text{
        font-size: 22px;
    }
}
@media (max-width:924px) {
    .gallery{
        min-height: 390px;
    }
    .gallery_title{
        font-size: 30px;
    }
    .gallery_text{
        font-size: 22px;
        padding: 14px;
        line-height: 1.2;
        letter-spacing: 1px;
    }
}
@media (max-width:824px) {
    .gallery{
        min-height: 460px;
    }
    .gallery_title{
        font-size: 30px;
    }
    .gallery_text{
        font-size: 22px;
        padding: 14px;
        line-height: 1.2;
        letter-spacing: 1px;
    }
}
@media (max-width: 500px) {
    .gallery_btn{
        width: 100%;
        border-radius: 6px;
    }
}
