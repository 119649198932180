.helpUs{
    position: relative;
    margin-top: 90px;
    width: 100%;
}
.helpUs_title{
    font-family: 'Real Truth';
    font-size: 36px;
    margin-bottom: 60px;
    margin-left: 0;
}
.helpUs_description{
    padding: 22px;
    font-family: 'izvest';
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: 1px;
}
.helpUs_wrapper{
    display: flex;
    padding-bottom: 60px;
}
.helpUs_text{
    width: 65%;
}
.helpUs_logo{
    display: flex;
    justify-content:flex-start;
}
.helpUs_logo-img{
    width: 200px;
    height: 200px;
}
.helpUs_btn{
    position: absolute;
    width: 220px;
    bottom: 0;
    right: 0;
    text-align: center;
    font-size: 21px;
    font-family: 'izvest';
    padding: 6px;
    border: 4px solid black;
    border-radius: 4px;
    font-size: 22px;
    background-color: inherit;
    cursor: pointer;
}
@media (max-width: 1140px) {
    .helpUs_title{
        margin: 0 0 30px 15px;
    }
}
@media (max-width: 1024px) {
    .helpUs_title{
        font-size: 30px;
    }
    .helpUs_description{
        font-size: 22px;
    }
}
@media (max-width: 924px) {
    .helpUs{
        min-height: 390px;
    }
    .helpUs_logo-img{
        width: 190px;
        height: 190px;
    }
    .helpUs_description{
        padding: 14px;
        line-height: 1.2;
        letter-spacing: 1px;
    }
}
@media (max-width: 624px) {
    .helpUs_wrapper{
        flex-direction: column;
    }
    .helpUs_logo{
        height: 260px;
        margin-bottom: 30px;
    }
    .helpUs{
        min-height: 390px;
    }
    .helpUs_logo-img{
        width: 260px;
        height: 260px;
    }
    .helpUs_description{
        padding: 14px;
        line-height: 1.2;
        letter-spacing: 1px;
    }
    .helpUs_text{
        width: 100%;
    }
}
@media (max-width: 500px) {
    .helpUs_btn{
        width: 100%;
        border-radius: 6px;
    }
}