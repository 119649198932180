.column{
    margin-top: 90px;
    width: 100%;
}
.column_title{
    font-family: 'Real Truth';
    font-size: 36px;
    margin-bottom: 30px;
    margin-left: 0;
}
.column_wrapper{
    display: flex;
}
.column_description{
    width: 55%;
    margin-left: 0;
    padding:  0 26px;
    font-family: 'izvest';
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: 1px;
}
.column_map{
    width: 50%;
    height: 320px;
}
.column_img{
    width: 100%;
    border: none;
    border-radius: 4px;
}
@media (max-width: 1140px) {
    .column_title{
        margin: 0 0 20px 15px;
    }
    .column_img{
        height: 320px;
    }
}
@media (max-width: 1024px) {
    .column_title{
        font-size: 30px;
    }
    .column_description{
        font-size: 22px;
    }
    .column_img{
        height: 280px;
    }
}
@media (max-width: 924px) {
    .column_title{
        font-size: 30px;
    }
    .column_description{
        padding:  0 14px;
        font-size: 22px;
        line-height: 1.3;
        letter-spacing: 1px;
    }
    .text_num{
        font-size: 27px;
    }
    .column_img{
        height: 260px;
    }
}
@media (max-width: 624px) {
    .column_wrapper{
        flex-direction: column;
    }
    .column_title{
        font-size: 30px;
    }
    .column_description{
        width: 100%;
        margin-bottom: 30px;
    }
    .column_map{
        width: 100%;
        height: 320px;
    }
    .column_img{
        height: 260px;
    }
}