.header_logo{
    display: inline-flex;
    align-items: center;
    width: 90px;
    height: 90px;
}

.header_logo-img{
    width: 70px;
    height: 70px;
}
@media (max-width:1140px) {
    .header_logo{
        width: 60px;
        height: 100%;
    }
    .header_logo-img{
        width: 54px;
        height: 54px;
    }
}
@media (max-width: 1024px) {
    .header_logo{
        width: 50px;
        height: 100%;
    }
    .header_logo-img{
        width: 44px;
        height: 44px;
    }
}
@media (max-width: 850px) {
    .header_logo{
        width: 38px;
        height: 100%;
    }
    .header_logo-img{
        width: 36px;
        height: 36px;
    }
    .header_logo-link{
        width: 36px;
        height: 36px;
    }
}
@media (max-width: 724px) {
    .header_logo{
        width: 32px;
        height: 100%;
    }
    .header_logo-img{
        width: 32px;
        height: 32px;
    }
    .header_logo-link{
        width: 32px;
        height: 32px;
    }
}
@media (max-width: 650px) {
    .header_logo{
        width: 50px;
        height: 100%;
    }
    .header_logo-img{
        width: 50px;
        height: 50px;
    }
    .header_logo-link{
        width: 50px;
        height: 50px;
    }
}
@media (max-width: 624px) {
    .header_logo{
        width: 100%;
        height: 100%;
    }
    .header_logo-img{
        width: 420px;
        height: 420px;
    }
    .header_logo-link{
        width: 420px;
        height: 420px;
    }
}
@media (max-width: 500px) {
    .header_logo-img{
        width: 380px;
        height: 380px;
    }
    .header_logo-link{
        width: 380px;
        height: 380px;
    }
}
@media (max-width: 424px) {
    .header_logo-img{
        width: 320px;
        height: 320px;
    }
    .header_logo-link{
        width: 320px;
        height: 320px;
    }
}
@media (max-width: 350px) {
    .header_logo-img{
        width: 300px;
        height: 300px;
    }
    .header_logo-link{
        width: 300px;
        height: 300px;
    } 
}