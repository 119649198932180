.payment{
    margin-top: 90px;
    width: 100%;
    min-height: 500px;
}
.payment_title{
    font-family: 'Real Truth';
    font-size: 36px;
    letter-spacing: 2px;
    margin-bottom: 60px;
    margin-left: 0;
}
.payment_description{
    padding: 22px;
    font-family: 'Roboto';
    font-size: 26px;
    line-height: 1.2;
    letter-spacing: 1.1px;
    margin-bottom: 60px;
}
.card-wrapper{
    width: 100%;
    min-height: 400px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 100px;
}
.card{
    width: 275px;
    display: flex;
    flex-direction: column;
}
.card_title{
    font-family: 'Roboto';
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 1.1px;
    margin-bottom: 30px;
}
.card_img{
    width: 270px;
    height: 270px;
    border: none;
    border-radius: 4px;
    object-fit: cover;
    margin-bottom: 30px;
}
.description{
    text-align: center;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: bold;
}
.button_wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 130px;
    margin-bottom: 50px;
}
.donate_link{
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
}
.tomain_link{
    position: absolute;
    right: 0;
    bottom: 0px;
    display: inline-block;
    text-decoration: none;
}

.tomain_btn{
    display: inline-block;
    width: 220px;
    text-align: center;
    text-decoration: none;
    font-size: 21px;
    font-family: 'izvest';
    padding: 6px;
    border: 5px solid black;
    border-radius: 5px;
    font-size: 22px;
    background-color: inherit;
}
@media (max-width: 1140px) {
    .payment_title{
        font-size: 30px;
    }
    .payment_description{
        font-size: 22px;
    }
    .card-wrapper{
        width: 100%;
        min-height: 400px;
        flex-wrap: wrap;
    }
}
@media (max-width: 599px) {
    .donate_link{
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 60px;
    }
    .tomain_btn{
        display: block;
        width: 100%;
    }
    .tomain_link{
        position: relative;
        display: block;
        width: 100%;
    }
    .button_wrapper{
        margin: 100px 0;
    }
}


