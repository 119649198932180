*{
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
}
#root{
    display: flex;
    justify-content: center;
}
body{
    background-image: url('../public/images/paper.jpg');
}
.App{
    width: 1202px;
    margin: 0 auto;
}
html{
    scroll-behavior: smooth;
}
.big_letter{
    font-size: 30px;
}
.text_part{
    margin-bottom: 20px ;
}
.red{
    color:#FF0000 ;
}
@font-face{
    font-family: 'Real Truth';
    src: local('Real Truth'),
    url('./fonts/Truth/Real Truth.ttf');
}
@font-face{
    font-family: 'izvest';
    src: local('izvest'), 
    url('./fonts/izvest/IZVESTIA.TTF');
}
@font-face{
    font-family: 'Roboto';
    src: local('Roboto'),
    url('./fonts/Roboto/RobotoCondensed-Black.ttf');
}
@media (max-width:1200px) {
    .App{
        width:98%;
    }
}
@media (max-width: 924px) {
    .App{
        width:96%;
    }
    .big_letter{
        font-size: 28px;      
    }
}
@media (max-width: 724px) {
    .App{
        margin: 0 auto;
        width:95%;
    }
}
@media (max-width: 500px) {
    .App{
        width: 94%;
    }
}





