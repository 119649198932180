.header_info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Real Truth';
    font-size: 40px;
    letter-spacing: 3px;
    margin-left: 0;
    margin-right: 0;
}
.header_info-img{
    margin-left: 10px;
    margin-right: 10px;
}
.header_info-title{
    font-size: 60px;
}
@media (max-width:1140px) {
    .header_info-img{
        width: 80px;
        height: 100%;
    }
}
@media (max-width:1024px) {
    .header_info-title{
        font-size: 54px;
    }
    .header_info-img{
        width: 60px;
        height: 100%;
    }
}
@media (max-width: 924px) {
    .header_info-title{
        font-size: 50px;
    }
    .header_info-img{
        width: 54px;
        height: 100%;
    }
}@media (max-width: 850px) {
    .header_info-title{
        font-size: 34px;
    }
    .header_info-img{
        width: 44px;
        height: 100%;
    }
}
@media (max-width: 724px) {
    .header_info-title{
        font-size: 38px;
    }
    .header_info-img{
        width: 44px;
        height: 100%;
    }
}
@media (max-width: 650px) {
    .header_info{
        flex-basis: 70%;
    }
    .header_info-title{
        display: none;
    }
    .header_info-img{
        display: none;
    }
    .info_title{
        display: block;
        font-size: 50px;
    }
    .header_info-img{
        width: 44px;
        height: 100%;
    }
}
@media (max-width: 424px) {
    .header_info{
        flex-basis: 50%;
    }
    .header_info-title{
        font-size: 44px;
    }
}
@media (max-width: 350px) {
    .header_info-title{
        font-size: 40px;
    }
}