.main_block{
    display: flex;
    width: 100%;
    height: 620px;
    background-image: url('/public/images/bw-main.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
}
.jubilee_side{
    position: relative;
    font-family: 'izvest';
    color: #fff;
}
.jubilee_position{
    position: absolute;
    top: 30px;
    right: 150px;
}
.promotion_timer{
    position: relative;
    color: #fff;
    font-size: 24px;
    font-family: 'izvest';
}
.days_num{
    font-size: 36px;
    font-family: 'Roboto';
    color: #FF0000;
}
.timer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 180px;
    height: 90px;
    position: absolute;
    top: 30px;
    left: 80px;
}
@media (max-width: 1140px) {
    .jubilee_position{
        top: 5%;
        right: 120px;
    }
}
@media (max-width: 1024px) {
    .jubilee_position{
        top: 5%;
        right: 100px;
    }
    .promotion_timer{
        font-size: 22px;
    }
    .days_num{
        font-size: 30px;
    }
    .timer{
        top: 30px;
        left: 60px;
    }
}
@media (max-width: 924px) {
    .main_block{
        height: 540px;
    }
    .jubilee_position{
        right: 85px;
    }
    .timer{
        top: 30px;
        left: 38px;
    }
}
@media (max-width: 824px) {
    .main_block{
        height: 490px;
    }
    .jubilee_position{
        right: 75px;
    }
    .timer{
        top: 30px;
        left: 10px;
    }
}
@media (max-width: 724px) {
    .main_block{
        height: 430px;
    }
    .jubilee_position{
        right: 55px;
    }
    .timer{
        top: 30px;
        left: -15px;
    }
}
@media (max-width: 624px) {
    .main_block{
        height: 330px;
    }
    .jubilee_position{
        right: 35px;
    }
    .timer{
        top: 30px;
        left: -45px;
    }
    .days_num{
        font-size: 34px;
    }
}
@media (max-width: 500px) {
    .main_block{
        height: 310px;
    }
    .jubilee_position{
        right: 15px;
    }
    .timer{
        top: 30px;
        left: -75px;
    }
    .days_num{
        font-size: 38px;
    }
    
}
@media (max-width:424px) {
    .jubilee_position{
        right: -10px;
    }
    .timer{
        top: 10px;
        left: -95px;
    }
}